import Routes from "../routes";

export const routes = {
  LOGIN: '/',
  LOGOUT: '/logout',
  REGISTER: '/register',
  HOME: '/home',

  B2B_CREATE_ORDER_IDENTIFICATION: '/b2b/criar-pedido/identificacao',
  B2B_CREATE_ORDER_CART: '/b2b/criar-pedido/carrinho/:person_uuid',
  B2B_ORDER_STATUS_USER: '/meu_pedido/:uuid',
  B2B_ORDER_STATUS: '/status_pedido/:uuid',

  PEDIDOS: '/pedidos',

  CHECKOUT: '/checkout/:uuid',

  ATENDIMENTO: '/atendimentos',
  ATENDIMENTO_EDIT: '/atendimentos/:id',
}

export const media_query = {
  DESKTOP_WIDTH: '(max-width: 1400px)',
  TABLET_WIDTH: '(min-width: 900px) and (max-width: 1249px)',
  MOBILE_WIDTH: '(max-width: 1200px)',
  MAX_PAGE_WIDTH: '(min-width: 1600px)',
}

export const USER_CONFIG = '@ADM_CANNECT_USER_CONFIG'
export const TOKEN_KEY = '@ADM_CANNECT_TOKEN'
export const MAX_WIDTH = '1500px'

export const CRM = [
  { name: 'CRO', checked: false, check: false },
  { name: 'CRM', checked: false, check: false },
  { name: 'CRMV', checked: false, check: false },
]

export const ADDRESS_STATES = [
  { uf: 'AC', state: 'Acre', checked: true, check: true },
  { uf: 'AL', state: 'Alagoas', checked: true, check: true },
  { uf: 'AP', state: 'Amapá', checked: true, check: true },
  { uf: 'AM', state: 'Amazonas', checked: true, check: true },
  { uf: 'BA', state: 'Bahia', checked: true, check: true },
  { uf: 'CE', state: 'Ceará', checked: true, check: true },
  { uf: 'DF', state: 'Distrito Federal', checked: true, check: true },
  { uf: 'ES', state: 'Espírito Santo', checked: true, check: true },
  { uf: 'GO', state: 'Goiás', checked: true, check: true },
  { uf: 'MA', state: 'Maranhão', checked: true, check: true },
  { uf: 'MT', state: 'Mato Grosso', checked: true, check: true },
  { uf: 'MS', state: 'Mato Grosso do Sul', checked: true, check: true },
  { uf: 'MG', state: 'Minas Gerais', checked: true, check: true },
  { uf: 'PA', state: 'Pará ', checked: false, check: true },
  { uf: 'PB', state: 'Paraíba', checked: false, check: true },
  { uf: 'PR', state: 'Paraná', checked: false, check: true },
  { uf: 'PE', state: 'Pernambuco', checked: true, check: true },
  { uf: 'PI', state: 'Piauí', checked: true, check: true },
  { uf: 'RJ', state: 'Rio de Janeiro', checked: true, check: true },
  { uf: 'RN', state: 'Rio Grande do Norte', checked: true, check: true },
  { uf: 'RS', state: 'Rio Grande do Sul', checked: true, check: true },
  { uf: 'RO', state: 'Rondônia', checked: true, check: true },
  { uf: 'RR', state: 'Roraima', checked: true, check: true },
  { uf: 'SC', state: 'Santa Catarina', checked: true, check: true },
  { uf: 'SP', state: 'São Paulo', checked: true, check: true },
  { uf: 'SE', state: 'Sergipe', checked: true, check: true },
  { uf: 'TO', state: 'Tocantins', checked: true, check: true },
]

export const FILTER_NAME_PRODUCTS = {
  brand_id: 'Marca',
  shape_id: 'Forma',
  class_id: 'Classe',
  subclass_id: 'subclass_id',
  country_id: 'country_id',
  supplier_id: 'supplier_id',
  cannect_choice: 'cannect_choice',
  concentracao_cdb: 'Concentração de CBD',
  thc_concentration: 'Concentração de THC',
  translated_name: 'translated_name',
  subcategory_id: 'subcategory_id',
  pathology: 'pathology',
  cannabidiol_id: 'Canabinoide',
  category_id: 'Categoria',
} as any

export interface SUBDOMAINS_TYPE {
  subdomain: string
  app: any
  main: boolean
  title?: string
  id: string
}

export const SUBDOMAINS: Array<SUBDOMAINS_TYPE>= [
  {
    subdomain: 'eliv',
    app: Routes,
    main: true,
    title: 'Eliv',
    id:'23'
  },
  {
    subdomain: 'usahemp',
    app: Routes,
    main: true,
    title: 'Usa Hemp',
    id: '11'
  },
  {
  subdomain: 'revivid',
  app: Routes,
  main: true,
  title: 'Revivid',
  id: '78'
},
{
  subdomain: 'volcanic',
  app: Routes,
  main: true,
  title: 'Volcanic',
  id: '77'
},
{
  subdomain: 'moonlion',
  app: Routes,
  main: true,
  title: 'Moonlion',
  id: '54'
},
{
  subdomain: 'valtellinamed',
  app: Routes,
  main: true,
  title: 'Valtellinamed',
  id: '64'
},
{
  subdomain: 'evona',
  app: Routes,
  main: true,
  title: 'Evona',
  id: '89'
},
{
  subdomain: 'nunaturelabs',
  app: Routes,
  main: true,
  title: 'NuNature Labs',
  id: '42'
},
{
  subdomain: 'behemp',
  app: Routes,
  main: true,
  title: 'Behemp',
  id: '93'
},
{
  subdomain: 'nexbiopharma',
  app: Routes,
  main: true,
  title: 'Nex Biopharma',
  id: '109'
},
{
  subdomain: 'serene',
  app: Routes,
  main: true,
  title: 'Serene Cbd',
  id: '101'
}
]

export const DESKTOP_WIDTH = '(min-width: 1250px)'
export const TABLET_WIDTH = '(min-width: 900px) and (max-width: 1249px)'
export const MOBILE_WIDTH = '(max-width: 900px)'
export const MAX_PAGE_WIDTH = '1366px;'
