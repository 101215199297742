import axios from 'axios'
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { validCPF } from 'utils/validations'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { api } from 'services/api'
import { TOKEN_KEY } from 'utils/constants'
import { useLocalStorage } from './useLocalStorage'
import { clearMask, formatZipCode, clearQuotation, normalizeName } from '../utils/formatters'
import { getApp, getBrandId } from '../utils/sub-domains'
import { getUserPermission } from './useSessionUser'

interface OrderB2cContextValues {
  itens: Item[]
  addToCart: (id: number, item: Item) => void
  removeCart: (id: number) => void
  setQuantity: (id: number, quantity: number) => void
  createOrder: () => Promise<Order>
  setPerson: any
  step: number
  maxStep: number
  setStep: any
  setMaxStep: any
  loading: boolean
  setLoading: any
  couponResponse: GetCouponResponse
  getDiscount: (items: Item[], coupon: string) => any
  changePerson: (fieldToChange: PersonFields, newValue: string, origin: string) => void
  order: Order
  finishOrder: () => Promise<Order>
  recipeSend: boolean
  setRecipeSend: any
  documents: Document[]
  setDocuments: any
  recipesDocuments: Document[]
  setRecipeDocuments: any
  statusDiscount: string
  couponIsValid: boolean
  discount: number
  shippingDiscount: number
  couponValue: string
  changeValueCoupon: (e: React.ChangeEvent<HTMLInputElement>) => void
  paymentMethod: PaymentMethod
  setPaymentMethod: any
  error: boolean
  setError: any
  getOrder: (uuid: string) => any
  person: Person
  searchUser: (type: string, value?: string) => Promise<void>
  changePrescriber: (fieldToChange: PrescriberFields, newValue: string) => void
  prescriber: Prescriber
  setPrescriber: any
  isModalOpen: boolean
  setIsModalOpen: any
  cpfIsValid: boolean
  setCpfIsValid: any
  activeInput: string
  setActiveInput: any
  search: string
  setSearch: any
  activeForm: string
  setActiveForm: any
  updatePerson: () => Promise<string>
  getProducts: () => void
  products: Products[]
  searchProducts: (search: string) => void
  filteredProducts: Products[]
  orderFinished: boolean
  setOrderFinished: any
  showFormCreateUser: boolean
  setShowFormCreateUser: any
  linkCart: string
  consultPrescriber: (prescriber: Prescriber) => void
  savePrescriber: () => void
  changeCreditCardInfo: (fieldToChange: CreditCardFields, newValue: string) => void
  creditCard: CreditCard
  shippingAddress: ShippingAddress
  setShippingAddress: any
  changeShippingAddress: (fieldToChange: ShippingAddressFields, newValue: string) => void
  fetchZipCode: (cep: string, type: 'shipping' | 'billing') => void
  activeBilling: boolean
  setActiveBilling: any
  changeBillingAddress: (fieldToChange: BillingAddressFields, newValue: string) => void
  billingAddress: BillingAddress
  personAddress: PersonAddress
  setPersonAddress: any
  changePersonAddress: (fieldToChange: PersonAddressFields, newValue: string) => void
  getMyOrders: (payload?: any) => void
  getCountOrders: (payload?: any) => void
  countAndTotalAmountsOrders: CountAndTotalAmountsOrdersProps | any
  setCountAndTotalAmountsOrders: any
  searchOrders: (search: string) => void
  pageOrders: string | number
  setPageOrders: any
  filteredList: boolean
  ordersAttendant: OrdersPayload
  filteredOrders: RowOrder[]
  filterParams: ParamsProps | any
  setFilterParams: any
  getOrderCheckout: (uuid: string) => void
  checkPixPaiment: (uuid: string) => any
  orderCheckout: OrderProps
  finishOrderCheckout: (uuid: string) => any
  finishOrderCheckoutByType: (uuid: string, type: PaymentMethod) => any
  clearOrder: () => any
  getRepresentants: () => Promise<Representant[]>
  representants: Representant[]
  changeRepresentant: (id: number) => void
  inputDisabled: boolean
  forcePage: number
  setForcePage: any
  representant: number | null
  getInstallments: (orderId: any) => void
  installments: any
}

interface Representant {
  id: number
  name: string
}
interface Item {
  id: number
  translated_name: string
  real_price: number
  quantity: number
  image: string
}

export interface CouponProps {
  name: string
  type_shipping: string
  value_shipping: number
  type: string
  value: number
  infos: CouponInfoProps[]
}

export interface CouponInfoProps {
  id: number
  product_id: number
  brand_id: number
}

export interface ProductProps {
  product_id: number
  quantity: number
  brand_id: number
  cannect_choice: boolean
  class_id: number
  country_id: number
  createdAt: string
  currency: string
  details: string
  image: string
  name: string
  price: number
  unitPrice: number
  route_of_administration_id: number
  shape_id: number
  status: string
  status_assistant: string
  subclass_id: number
  supplier_id: number
  translated_name: string
  updatedAt: string
  uuid: string
  weight: null
}

interface Products {
  id: number
  translated_name: string
  real_price: number
  media: {
    path: string
    format: string
  }[]
  quantity: 0
}

type CreditCardFields =
  | 'card_name'
  | 'card_number'
  | 'card_month_validate'
  | 'card_year_validate'
  | 'card_cvv'
  | 'installments'

interface Response {
  order: OrderResponse
}

export interface Prescriber {
  nr_council?: string
  uf_council?: string
  council?: string
  name?: string
  id?: number
}

interface OrderResponse {
  id: number
  amount: string
  anvisa_id: number
  anvisaDoc: DocOrderResponse
  carriers: number
  cep: string
  city: string
  complement: string
  cannect_user_id: number
  coupon: string
  coupon_id: number
  delivery_forecast: string
  identidadeDoc: DocOrderResponse
  identidadeId: number
  items: ItemsOrderResponse[]
  link: string
  origin: string
  originCheckout: string
  person_id: string
  recipes: RecipeOrderResponse[]
  residencialDoc: DocOrderResponse
  residencial_id: number
  shipping: string
  shippingDiscount: string
  state: string
  status: string
  street: string
  user_id: string
  user: any
  uuid: string
}

interface DocOrderResponse {
  id: number
  person_id: number
  extension: string
  status: string
  type: string
  url: string
}

interface RecipeOrderResponse {
  id: number
  link_pdf: string
  order_id: number
}
interface ItemsOrderResponse {
  amount: string
  id: number
  product_id: number
  quantity: number
  unitPrice: string
  order_id: number
  product: ItemOrderResponse
}

interface ItemOrderResponse {
  brand_id: number
  currency: string
  free_shipping: boolean
  name: string
  price: number
  translated_name: string
  media: MediaItemOrderResponse[]
}
interface MediaItemOrderResponse {
  format: string
  id: number
  path: string
  product_id: number
}
interface Order {
  id?: number | any
  status?: string
  linkCart?: string
}

interface Document {
  id: number
  type: string
}

interface ShippingAddress {
  cep?: string
  state?: string
  city?: string
  address_number?: string
  street?: string
  district?: string
  complement?: string
}

type ShippingAddressFields = 'cep' | 'state' | 'city' | 'address_number' | 'street' | 'district' | 'complement'

type PersonFields = 'email' | 'name' | 'cpf' | 'phone' | 'formatBirthday' | 'phone_residencial' | ''

type PrescriberFields = 'nr_council' | 'uf_council' | 'council' | 'name' | ''

interface GetCouponResponse {
  coupon: CouponResponse
  items: ItemsCouponResponse[]
}

interface CouponResponse {
  type: string
  type_shipping: string
  value: any
  value_shipping: any
  name: string
}

interface ItemsCouponResponse {
  amount: number
  discount: 0
  product: any
  product_id: number
  quantity: number
  unitPrice: number
}

export interface Person {
  uuid?: string
  id?: number
  name?: string
  email?: string
  cpf?: string
  formatBirthday?: string
  phone?: string
  phone_residencial?: string
}

interface PersonAddress {
  id?: number
  address?: string
  cep?: string
  city?: string
  complement?: string
  district?: string
  state?: string
  number?: string
  type?: string
}

interface Coupon {
  type?: string
  type_shipping?: string
  value?: string
  value_shipping?: string
  name?: string
  id?: number
}


export interface PaymentMethod {
  type: 'credit_card' | 'pix' | 'boleto'
}

interface CreditCard {
  card_name?: string
  card_number?: string
  card_month_validate?: string
  card_year_validate?: string
  card_cvv?: string
  installments?: number
}

interface BillingAddress {
  cep?: string
  state?: string
  city?: string
  address_number?: string
  street?: string
  district?: string
  complement?: string
}

type FormsType = 'search-user' | 'data-user' | 'create-user'

type BillingAddressFields = 'cep' | 'state' | 'city' | 'address_number' | 'street' | 'district' | 'complement'

type PersonAddressFields = 'cep' | 'state' | 'city' | 'complement' | 'district' | 'number' | 'address'

interface CartCheckoutProviderProps {
  children: ReactNode
}

export interface OrdersPayload {
  count?: number
  total_amout?: number
  rows: RowOrder[]
}

export interface RowOrder {
  id: number
  uuid: string
  status_order: string
  status_order_color: string
  status_payment: string
  status_payment_color: string
  createdAt: Date
  name: string
  amount: string | number
}

export interface ItemOrder {
  subtotal: number
  id: number
  amount: string
  discount: string
  order_id: number
  quantity: number
  unitPrice: string
  imputed_value?: string

  product: {
    name: string
    price: string
    media: MediaProduct[]
    translated_name: string
    currency: string
    status?: string
    status_b2b?: string
  }
}

export interface MediaProduct {
  path: string
}

export interface Status {
  order_status: {
    color: string
    date: string
    status: string
    translated_name: string
  }
  payment_status: {
    color: string
    date: string
    status: string
    translated_name: string
  }
}

export interface PersonOrder {
  id: number
  name: string
}

export interface OrderProps {
  id?: number
  uuid?: string
  amount?: string
  address_number?: string
  cep?: string
  city?: string
  complement?: string
  coupon?: Coupon
  state?: string
  district?: string
  createdAt: Date
  status?: Status
  shipping?: string
  shippingDiscount?: string
  discount?: string
  items: ItemOrder[]
  originCheckout?: string
}

export interface Attendand {
  id: number
  name: string
}

export interface CountAndTotalAmountsOrdersProps {
  count: number
  total_amount: string
}

export interface ParamsProps {
  search: string
  limit: number
  page: any
}

interface InstallmentsProps {
  id: number
  installment: string
  amount: number
  feels: string
}

const OrderB2cContext = createContext({} as OrderB2cContextValues)

export const OrderB2cProvider = ({ children }: CartCheckoutProviderProps) => {
  const location = useLocation()
  const isUsaHemp = location?.pathname?.includes('usahemp')

  const [step, setStep] = useState(0)
  const [maxStep, setMaxStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [couponResponse, setCouponResponse] = useState<GetCouponResponse>({
    items: [],
    coupon: {
      type: '',
      type_shipping: '',
      value: '',
      value_shipping: '',
      name: '',
    },
  })
  const [couponIsValid, setCouponIsValid] = useState(false)
  const [statusDiscount, setStatusDiscount] = useState('')
  const [coupon, setCoupon] = useState<Coupon>({})

  const [inputCoupon, setInputCoupon] = useState<any>({
    couponValue: '',
  })

  let totalProductValue = 0
  let totalDiscountValue = 0

  const { couponValue } = inputCoupon

  const changeValueCoupon = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target

    setInputCoupon((inputValue: any) => ({ ...inputValue, [id]: value }))
  }

  const [discount, setDiscount] = useState(0)
  const [shippingDiscount, setShippingDiscount] = useState(0)
  const [documents, setDocuments] = useState<Document[]>([])
  const [recipesDocuments, setRecipeDocuments] = useState<Document[]>([])

  const [recipeSend, setRecipeSend] = useState(false)
  const [error, setError] = useState(false)
  const [activeBilling, setActiveBilling] = useState(false)

  const history = useHistory()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [cpfIsValid, setCpfIsValid] = useState(true)
  const [activeInput, setActiveInput] = useState('cpf')
  const [search, setSearch] = useState('')
  const [pageOrders, setPageOrders] = useState(0)
  const [filteredList, setFilteredList] = useState(false)
  const [activeForm, setActiveForm] = useState<FormsType>('search-user')
  const [showFormCreateUser, setShowFormCreateUser] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({ type: 'credit_card' })
  const [creditCard, setCreditCard] = useState<CreditCard>({
    installments: 1,
    card_month_validate: '01',
    card_year_validate: '24',
  })

  const [shippingAddress, setShippingAddress] = useState<ShippingAddress>({})
  const [billingAddress, setBillingAddress] = useState<BillingAddress>({})
  const [ordersAttendant, setOrdersAttendant] = useState<OrdersPayload>({
    rows: [],
  })

  const [cartItems, setCartItems] = useLocalStorage<Item[]>('@CANNECT:ORDER_B2B_CART', [])
  const [order, setOrder] = useLocalStorage<Order>('@CANNECT:ORDER_B2B', {})
  const [person, setPerson] = useLocalStorage<Person>('@CANNECT:ORDER_B2B_PERSON', {})
  const [personAddress, setPersonAddress] = useLocalStorage<PersonAddress>('@CANNECT:ORDER_B2B_PERSON_ADDRESS', {})

  const [linkCart, setLinkCart] = useState('')

  const [products, setProducts] = useState<Products[]>([])
  const [filteredProducts, setFilteredProducts] = useState<Products[]>([])
  const [filteredOrders, setFilteredOrders] = useState<RowOrder[]>([])
  const [filterParams, setFilterParams] = useState<ParamsProps>()

  const [prescriber, setPrescriber] = useState<Prescriber>({})
  const [orderCheckout, setOrderCheckout] = useState<OrderProps>({
    createdAt: new Date(),
    items: [],
  })

  const [representants, setRepresentants] = useState<Representant[]>([])
  const [representant, setRepresentant] = useState<number | null>(null)

  const [orderFinished, setOrderFinished] = useState(false)

  const [inputDisabled, setInputDisabled] = useState<boolean>(false)

  const [countAndTotalAmountsOrders, setCountAndTotalAmountsOrders] = useState<CountAndTotalAmountsOrdersProps>()

  const [forcePage, setForcePage] = useState<number>(0)

  const [installments, setInstallments] = useState<InstallmentsProps[]>()

  function addToCart(id: number, item: Item) {
    toast.success('Produto adicionado ao carrinho')
    setCartItems(currItems => {
      if (currItems.find(item => item.id === id) == null) {
        return [
          ...currItems,
          { id, quantity: 1, translated_name: item.translated_name, image: item.image, real_price: item.real_price },
        ]
      }
      return currItems.map(item => {
        if (item.id === id) {
          return { ...item, quantity: item.quantity + 1 }
        }
        return item
      })
    })
  }

  function removeCart(id: number) {
    setCartItems(currItems => {
      return currItems.filter(item => item.id !== id)
    })
  }

  async function setQuantity(id: number, quantity: number) {
    setCartItems(currItems => {
      return currItems.map(item => {
        if (item.id === id) {
          return { ...item, quantity }
        }
        return item
      })
    })
  }

  function changeCreditCardInfo(fieldToChange: CreditCardFields, newValue: string) {
    console.log('fieldToChange-->', fieldToChange, 'newValue-->', newValue)
    setCreditCard({
      ...creditCard,
      [fieldToChange]: newValue,
    })
  }

  function changeShippingAddress(fieldToChange: ShippingAddressFields, newValue: string) {
    setShippingAddress({
      ...shippingAddress,
      [fieldToChange]: newValue,
    })
    if (fieldToChange === 'cep') {
      if (newValue.length === 9) {
        fetchZipCode(clearMask(newValue), 'shipping')
      }
    }
    console.log(shippingAddress)
  }

  function changeBillingAddress(fieldToChange: BillingAddressFields, newValue: string) {
    setBillingAddress({
      ...billingAddress,
      [fieldToChange]: newValue,
    })
    if (fieldToChange === 'cep') {
      if (newValue.length === 9) {
        fetchZipCode(clearMask(newValue), 'billing')
      }
    }
    console.log(billingAddress)
  }

  function changeRepresentant(id: number) {
    setRepresentant(id)
  }
  const defaultShippingValue = useMemo(() => {
    return isUsaHemp ? 140 : 140
  }, [isUsaHemp])

  async function fetchZipCode(cep: string, type: 'shipping' | 'billing' | 'person') {
    setLoading(true)
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (!response?.data.erro) {
        if (type === 'shipping') {
          setShippingAddress(state => ({
            ...state,
            cep: formatZipCode(response?.data?.cep ? response?.data?.cep : cep),
            state: response?.data?.uf ? response?.data?.uf : state.state,
            street: response?.data?.logradouro ? response?.data?.logradouro : state.street,
            district: response?.data?.bairro ? response?.data?.bairro : state.district,
            city: response?.data?.localidade ? response?.data?.localidade : state.city,
          }))
        }
        if (type === 'billing') {
          setBillingAddress(state => ({
            ...state,
            cep: formatZipCode(response?.data?.cep ? response?.data?.cep : cep),
            state: response?.data?.uf ? response?.data?.uf : state.state,
            street: response?.data?.logradouro ? response?.data?.logradouro : state.street,
            district: response?.data?.bairro ? response?.data?.bairro : state.district,
            city: response?.data?.localidade ? response?.data?.localidade : state.city,
          }))
        }
        if (type === 'person') {
          console.log(response.data)

          setPersonAddress(state => ({
            ...state,
            cep: formatZipCode(response?.data?.cep ? response?.data?.cep : cep),
            state: response?.data?.uf ? response?.data?.uf : state.state,
            address: response?.data?.logradouro ? response?.data?.logradouro : state.address,
            district: response?.data?.bairro ? response?.data?.bairro : state.district,
            city: response?.data?.localidade ? response?.data?.localidade : state.city,
          }))
        }
      } else {
        toast.error(`CEP não encontrado. Por favor, digite os dados de endereço.`)
        if (type === 'shipping') {
          setShippingAddress({
            cep,
            state: '',
            street: '',
            district: '',
            city: '',
            address_number: '',
            complement: '',
          })
        }

        if (type === 'billing') {
          setBillingAddress({
            cep,
            state: '',
            street: '',
            district: '',
            city: '',
            address_number: '',
            complement: '',
          })
        }

        if (type === 'person') {
          setPersonAddress({
            cep,
            state: '',
            address: '',
            district: '',
            city: '',
            number: '',
            complement: '',
          })
        }
      }
    } catch (err: any) {
      setLoading(false)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  async function createOrder() {
    try {
      const brand = getApp()
      setError(false)
      setLoading(true)
      if (!order?.id) {
        const newOrderRequestItems: any[] = []

        cartItems?.forEach(product => {
          const newOrderRequestItem: any = {}

          newOrderRequestItem.product_id = product.id
          newOrderRequestItem.quantity = product.quantity

          newOrderRequestItems.push(newOrderRequestItem)
        })

        const payload = {
          items: newOrderRequestItems,
          coupon,
          documents,
          recipes: recipesDocuments,
          person_uuid: person.uuid,
          prescriber,
          brand,
          representant,
        }

        const response = await api
          .post('/order_b2b/create_order', payload)
          .then(res => {
            setLoading(false)
            console.log(res)

            setError(false)
            setLinkCart(res?.data?.uuid)
            setOrderFinished(true)
            setPerson({})
            setPersonAddress({})
            setCartItems([])
            setDocuments([])
            setPrescriber({})
            setProducts([])
            setOrder({})
            setActiveForm('search-user')
            setSearch('cpf')
            setRecipeDocuments([])
          })
          .catch(err => {
            setError(true)
            setLoading(false)
            return err
          })

        return response?.data?.order
      }
      return order?.id
    } catch (error) {
      setError(true)
      setLoading(false)
      return error
    } finally {
      setLoading(false)
    }
  }

  async function getDiscount(items: Item[], coupon: string) {
    try {
      const brand = getApp()
      setLoading(true)
      setStatusDiscount('')
      const payload = {
        coupon: {
          name: coupon,
        },
        items: items.map(item => {
          return {
            product_id: item.id,
            ...item,
          }
        }),
        brand,
      } as any

      const { data } = await api.post(`/coupon/valid-items`, payload)

      const { totalDiscount, shippingDiscount } = calculateDiscounts(data.coupon, data.items)

      totalDiscountValue = totalDiscount
      console.log(totalDiscountValue)

      setShippingDiscount(shippingDiscount)
      setDiscount(totalDiscountValue)
      setCouponResponse(data)

      setCoupon({ name: coupon })
      setCouponIsValid(true)
    } catch (e) {
      setLoading(false)
      setDiscount(0)
      console.log(e)

      setStatusDiscount('Cupom inválido')
      setCoupon({ name: '' })
      setCouponIsValid(false)
    } finally {
      setLoading(false)
    }
  }

  const calculateDiscounts = (coupon: CouponProps, products: ProductProps[]) => {
    totalProductValue = products.reduce((sum, product) => sum + product.unitPrice * product.quantity, 0)

    console.log(coupon)

    if (coupon.infos && coupon.infos.length > 0) {
      totalDiscountValue = calculateDiscountByInfos(coupon, products)
    } else {
      totalDiscountValue = coupon.type === 'percent' ? (coupon.value / 100) * totalProductValue : coupon.value
    }

    const shippingDiscountValue =
      // eslint-disable-next-line no-nested-ternary
      coupon.value_shipping > 0
        ? coupon.type_shipping === 'percent'
          ? (coupon.value_shipping / 100) * defaultShippingValue
          : coupon.value_shipping
        : 0

    return {
      totalDiscount: Number(totalDiscountValue),
      totalProduct: totalProductValue,
      shippingDiscount: shippingDiscountValue,
    }
  }

  const calculateDiscountByInfos = (coupon: CouponProps, products: any[]): number => {
    let brandsFinded: number[] = []
    let productsFinded: number[] = []
    let discount = 0

    brandsFinded = coupon.infos.filter(info => info.brand_id).map(info => info.brand_id)
    productsFinded = coupon.infos.filter(info => info.product_id).map(info => info.product_id)

    products.forEach(product => {
      if (
        productsFinded.length <= 0 &&
        brandsFinded.length > 0 &&
        brandsFinded.some(brandId => product.product.brand.id === brandId)
      ) {
        discount += calculateDiscountPerProduct(coupon, product)
      } else if (productsFinded.length > 0 && productsFinded.some(productId => product.product_id === productId)) {
        discount += calculateDiscountPerProduct(coupon, product)
      }
    })
    return discount
  }

  const calculateDiscountPerProduct = (coupon: CouponProps, product: any): number => {
    return coupon.type === 'percent'
      ? (coupon.value / 100) * product.unitPrice * product.quantity
      : coupon.value * product.quantity
  }

  async function changePerson(fieldToChange: PersonFields, newValue: string, origin: string) {
    if (fieldToChange === 'phone' || fieldToChange === 'phone_residencial') {
      const formattedPhone = clearQuotation(newValue)
      setPerson({
        ...person,
        [fieldToChange]: formattedPhone,
      })
    } else if (fieldToChange === 'cpf') {
      const newValueFromated = newValue.replace(/\D/g, '')
      if (origin === 'searchCPF' && newValueFromated.length >= 11) {
        const valid = validCPF(newValueFromated)
        if (!valid) {
          setCpfIsValid(false)
          setIsModalOpen(true)
        } else {
          const clearCpf = clearMask(newValueFromated)
          setPerson({
            ...person,
            [fieldToChange]: clearCpf,
          })
          setCpfIsValid(true)
          setIsModalOpen(false)

          const response = await searchUser(fieldToChange, clearCpf)

          if (!response.data) {
            setIsModalOpen(true)
          }
          setCpfIsValid(true)
          setIsModalOpen(false)
        }
      } else {
        const clearCpf = clearMask(newValue)
        setPerson({
          ...person,
          [fieldToChange]: clearCpf,
        })
      }
      if (origin === 'fomrInput' && newValue.length >= 14) {
        const valid = validCPF(newValue)
        if (!valid) {
          setCpfIsValid(false)
          setIsModalOpen(true)
        } else {
          const clearCpf = clearMask(newValue)
          setPerson({
            ...person,
            [fieldToChange]: clearCpf,
          })
          setCpfIsValid(true)
          setIsModalOpen(false)
        }
      } else {
        const clearCpf = clearMask(newValue)
        setPerson({
          ...person,
          [fieldToChange]: clearCpf,
        })
      }
    } else {
      setPerson({
        ...person,
        [fieldToChange]: newValue,
      })
    }
  }

  function changePersonAddress(fieldToChange: PersonAddressFields, newValue: string) {
    setPersonAddress({
      ...personAddress,
      [fieldToChange]: newValue,
    })
    if (fieldToChange === 'cep') {
      if (newValue.length === 9) {
        fetchZipCode(clearMask(newValue), 'person')
      }
    }
    console.log(personAddress)
  }

  function changePrescriber(fieldToChange: PrescriberFields, newValue: string) {
    setPrescriber({
      ...prescriber,
      [fieldToChange]: newValue,
    })
    console.log(prescriber)
  }

  async function finishOrder() {
    try {
      setLoading(true)
      const payload = {
        recipes: recipesDocuments,
      }
      const response = await api
        .put(`/order_cart/${order.id}`, payload)
        .then(res => {
          console.log(res)
          setError(false)
          setStep(1)
          setMaxStep(2)
          setOrder({})
          setCartItems([])
          setRecipeSend(false)
          setPrescriber({})
          return res
        })
        .catch(err => {
          setError(true)
          console.log(err)

          return err
        })

      return response?.data?.order
      // eslint-disable-next-line no-unreachable
    } catch (error) {
      setLoading(false)
      setError(true)
      return error
    } finally {
      setLoading(false)
    }
  }

  async function getOrder(uuid: string) {
    try {
      setLoading(true)
      const { data } = await api.get<Response>(`/order_b2c/${uuid}`)

      if (data) {
        setCartItems([])

        data?.order.items?.map(product => {
          setCartItems(currItems => {
            if (currItems.find(item => item.id === product.id) == null) {
              return [
                ...currItems,
                {
                  id: product.id,
                  quantity: product.quantity,
                  translated_name: product.product.translated_name,
                  image:
                    product.product.media.length > 0
                      ? product.product.media[0].path
                      : 'https://s3.amazonaws.com/cannect.portal.upload/produtos/14517/foto%201.jpg',
                  real_price: product.product.price,
                },
              ]
            }
            return currItems.map(item => {
              if (item.id === product.id) {
                return { ...item, quantity: item.quantity + 1 }
              }
              return item
            })
          })

          return product
        })

        setOrder({ id: data.order.id, status: data.order.status })
      }
      setLoading(false)
      return data
    } catch (error) {
      return error
    }
  }

  const getOrderCheckout = async (uuid: string) => {
    try {
      setLoading(true)
      const { data } = await api.get(`/order_b2b_status/${uuid}`)
      console.log(data)

      setOrderCheckout(data.order)
      setShippingAddress({
        cep: data?.order?.cep,
        address_number: data?.order?.address_number,
        city: data?.order?.city,
        state: data?.order?.state,
        complement: data?.order?.complement,
        street: data?.order?.street,
      })
      setLoading(false)
      return order
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  const checkPixPaiment = async (uuid: string) => {
    try {
      const { data } = await api.get(`/order_b2b_status/${uuid}`)

      return data?.order?.status?.payment_status?.status
    } catch (error) {
      return error
    }
  }

  async function finishOrderCheckout(uuid: string) {
    try {
      setLoading(true)
      const payload = {
        shippingAddress,
        paymentMethod,
        creditCard,
      }

      const response = await api
        .put(`/order_b2b/${uuid}`, payload)
        .then(res => {
          setLoading(false)
          setError(false)
          setOrder({})
          setOrderCheckout({
            createdAt: new Date(),
            items: [],
          })
          setShippingAddress({})
          setCreditCard({})
          return res
        })
        .catch(error => {
          setLoading(false)
          setError(true)
        })

      setLoading(false)
      return response
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  async function finishOrderCheckoutByType(uuid: string, payment: PaymentMethod | any) {
    try {
      setLoading(true)
      setPaymentMethod({ ...paymentMethod, type: payment })
      const payload = {
        shippingAddress,
        paymentMethod,
        creditCard,
      }

      const response = await api
        .put(`/order_b2b/${uuid}`, payload)
        .then(res => {
          setLoading(false)
          setError(false)
          return res
        })
        .catch(error => {
          setLoading(false)
          setError(true)
          return error
        })

      setLoading(false)
      return response
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  async function searchUser(type: string, value?: string): Promise<any> {
    setLoading(true)
    setIsModalOpen(false)

    const formatedSearch = value ? value.match(/\d/g)?.join('') : person?.cpf?.match(/\d/g)?.join('')

    await api
      .post(`order_b2c/get_person?${type}=${formatedSearch}`)
      .then(res => {
        console.log(res.data.person)
        const personalAddress = res.data.person.addresses.find((add: PersonAddress) => add.type === 'PERSONAL')
        if (personalAddress) {
          setPersonAddress(personalAddress)
        }
        setPerson(res.data.person)
        setLoading(false)
        setActiveForm('data-user')
        setInputDisabled(true)
        return res.data.person
      })
      .catch(err => {
        setInputDisabled(false)
        setLoading(false)
        setIsModalOpen(true)
        return err
      })
  }

  async function updatePerson(): Promise<string> {
    const payload = {
      person,
      personAddress,
    }
    const { data } = await api.post('/order_b2b/create_person', payload)
    setPerson(data?.person)
    return data?.person.uuid
  }

  async function searchProducts(search: string) {
    const filtered = products.filter(product =>
      product.translated_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )

    setFilteredProducts(filtered)
  }

  const getMyOrders = async (payload?: any) => {
    let params
    if (payload) {
      params = {
        search: payload.search ? payload.search : undefined,
        limit: payload.limit,
        page: payload.page,
      }
    }

    if (!filterParams && !payload) {
      params = {
        search: undefined,
        limit: 10,
        page: 0,
      }
    } else if (filterParams && !payload) {
      params = {
        search: filterParams?.search,
        limit: filterParams?.limit,
        page: pageOrders,
      }

      setFilterParams(params)
    }

    try {
      setLoading(true)
      const brand = getApp()

      const { data } = await api.get(`/all_orders_by_role_b2b/${brand}`, { params })

      setOrdersAttendant(data?.orders)
      setFilteredOrders(data?.orders)
      getCountOrders(params)
      setLoading(false)
      return data
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  const getCountOrders = async (payload?: any) => {
    try {
      let params
      if (payload) {
        params = payload
      }
      if (!payload && filterParams) {
        params = filterParams
      }

      setLoading(true)
      const brand = getApp()

      const { data } = await api.get(`/count_orders/${brand}`, { params })
      setCountAndTotalAmountsOrders(data.ordersCount)
      setLoading(false)
      return data
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  async function searchOrders(search: string) {
    let params: any = {
      limit: 10,
      page: 0,
    }
    setPageOrders(0)
    setForcePage(0)
    setFilterParams(params)

    if (search.length === 0) {
      setFilteredList(false)

      params = {
        search: undefined,
        limit: 10,
        page: 0,
      }
      setFilterParams(params)
      getMyOrders(params)
    } else {
      params = {
        search,
        limit: 10,
        page: pageOrders,
      }

      if (pageOrders) {
        const num = pageOrders.toString()
        setForcePage(Number(num[0]))
      }

      setFilteredList(true)
      setFilterParams(params)
      getMyOrders(params)
    }
  }

  async function getProducts() {
    setLoading(true)
    const brandId = getBrandId()
    await api
      .get(`/representants_all_products?brandId=${brandId}`)
      .then(res => {
        console.log(products)

        setProducts(res.data.products)
        setFilteredProducts(res.data.products)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })

    console.log(products)
  }

  async function consultPrescriber(prescriber: Prescriber) {
    try {
      setLoading(true)
      const { data } = await api.post('/consult-cfm', prescriber)

      if (data?.cfmResponse?.name) {
        setPrescriber({
          ...prescriber,
          name: data.cfmResponse.name,
        })
      } else if (data?.result?.professional_name) {
        setPrescriber({
          ...prescriber,
          name: data.result.professional_name,
        })
      } else {
        toast.warn(
          'Houve algum problema ao buscar os dados do Conselho. Por favor, digite o nome do profissional de saúde e clique em "SALVAR" para continuar.'
        )
        setPrescriber(prescriber)
      }
    } catch (error: any) {
      toast.error(
        `${error.response.data.message}. Digite o nome do profissional de saúde e clique em "SALVAR" para continuar.`
      )
      setPrescriber(prescriber)
    } finally {
      setLoading(false)
    }
  }

  async function savePrescriber() {
    try {
      const payload = {
        name: prescriber?.name,
        nr_council: prescriber?.nr_council,
        uf_council: prescriber?.uf_council,
        council: prescriber.council,
      }
      const { data } = await api.post(`/prescribers/get-or-save`, payload)

      setPrescriber({
        name: data?.prescriber?.name,
        nr_council: data?.prescriber?.nr_council,
        uf_council: data?.prescriber?.uf_council,
        council: prescriber.council,
        id: data?.prescriber?.id,
      })
      console.log(prescriber)
      return prescriber
    } catch (error) {
      return error
    }
  }

  async function clearOrder() {
    setOrder({})
    setOrderCheckout({
      createdAt: new Date(),
      items: [],
    })
    setActiveForm('search-user')
    setShippingAddress({})
    setCreditCard({})
    setPersonAddress({})
    setPerson({})
    setDocuments([])
    setDiscount(0)
    setOrderCheckout({
      createdAt: new Date(),
      items: [],
    })
    setCartItems([])
    setSearch('')

    console.log('chamou ordeeeer')
  }

  async function getRepresentants() {
    try {
      const brand = getApp()
      const { data } = await api.get(`/representants/${brand}`)

      setRepresentants(data.representants)

      return data.representants
    } catch (error) {
      return error
    }
  }

  async function getInstallments(orderId: any) {
    try {
      setLoading(true)
      const { data } = await api.get(`/payments/installments_public/${orderId}`)
      setLoading(false)
      setInstallments(data?.installments)
      return data
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  useEffect(() => {
    if (order?.id) {
      if (order?.id) {
        setStep(2)
        setMaxStep(3)
      } else {
        setStep(1)
      }
    } else {
      setStep(1)
    }
  }, [])

  useEffect(() => {
    if (order?.id) {
      const newOrderRequestItems: any[] = []

      cartItems?.forEach(product => {
        const newOrderRequestItem: any = {}

        newOrderRequestItem.product_id = product.id
        newOrderRequestItem.quantity = product.quantity

        newOrderRequestItems.push(newOrderRequestItem)
      })
    }
  }, [setCartItems, cartItems])

  useEffect(() => {
    if (cartItems.length === 0 && order?.id) {
      setOrder({})
      setStep(1)
    }
    if (cartItems.length === 0) {
      setStep(1)
      setMaxStep(2)
    }
  }, [cartItems])

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <OrderB2cContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        itens: cartItems,
        addToCart,
        removeCart,
        setQuantity,
        createOrder,
        step,
        maxStep,
        setStep,
        loading,
        setLoading,
        getDiscount,
        couponResponse,
        order,
        setMaxStep,
        finishOrder,
        recipeSend,
        setRecipeSend,
        documents,
        setDocuments,
        statusDiscount,
        couponIsValid,
        discount,
        shippingDiscount,
        couponValue,
        changeValueCoupon,
        error,
        setError,
        recipesDocuments,
        setRecipeDocuments,
        getOrder,
        person,
        searchUser,
        isModalOpen,
        setIsModalOpen,
        cpfIsValid,
        setCpfIsValid,
        activeInput,
        setActiveInput,
        search,
        setSearch,
        activeForm,
        setActiveForm,
        updatePerson,
        getProducts,
        products,
        searchProducts,
        filteredProducts,
        orderFinished,
        showFormCreateUser,
        setShowFormCreateUser,
        changePerson,
        setPerson,
        linkCart,
        setOrderFinished,
        changePrescriber,
        prescriber,
        setPrescriber,
        consultPrescriber,
        savePrescriber,
        paymentMethod,
        setPaymentMethod,
        changeCreditCardInfo,
        creditCard,
        shippingAddress,
        setShippingAddress,
        changeShippingAddress,
        activeBilling,
        setActiveBilling,
        changeBillingAddress,
        billingAddress,
        fetchZipCode,
        personAddress,
        changePersonAddress,
        getMyOrders,
        getCountOrders,
        countAndTotalAmountsOrders,
        setCountAndTotalAmountsOrders,
        searchOrders,
        setPageOrders,
        pageOrders,
        filteredList,
        ordersAttendant,
        filteredOrders,
        filterParams,
        setFilterParams,
        getOrderCheckout,
        checkPixPaiment,
        orderCheckout,
        finishOrderCheckout,
        finishOrderCheckoutByType,
        clearOrder,
        setPersonAddress,
        getRepresentants,
        representants,
        changeRepresentant,
        inputDisabled,
        forcePage,
        setForcePage,
        representant,
        getInstallments,
        installments,
      }}
    >
      {children}
    </OrderB2cContext.Provider>
  )
}
export function useOrderB2c() {
  return useContext(OrderB2cContext)
}
