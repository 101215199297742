import React, { useState } from 'react'
import Text from 'components/Text'
import { Button, Input } from 'design-cannect'
import Checkbox from 'components/Checkbox'
import Loading from 'components/Loading'
import { useHistory } from 'react-router-dom'
import { setItem, setItemUser } from 'utils/persistenceUtils'
import { MOBILE_WIDTH, routes, TOKEN_KEY } from 'utils/constants'
import { api, defaultsHeadersAxios } from 'services/api'
import useToast from 'hooks/useToast'
import { getApp } from 'utils/sub-domains'
import { themes } from 'styles/theme/brand-theme'
import useMediaQuery from 'hooks/useMediaQuery'
import * as Styles from './styles'

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [checked, setChecked] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { ToastSuccess } = useToast()
  const history = useHistory()

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const brand = getApp()

  const redirectToWhats = () => {
    window.location.href = 'https://api.whatsapp.com/send?phone=5511997685052'
  }

  const login = async () => {
    try {
      setLoading(true)
      setError(false)
      const { data } = await api.post('/authenticate_brand', { email, password, brand })
      const { token, user } = data
      ToastSuccess('Login realizado com sucesso!')
      setItemUser(user)
      sessionStorage.setItem(TOKEN_KEY, token)
      defaultsHeadersAxios(token)
      setItem(TOKEN_KEY, token)
      history.push(routes.PEDIDOS)
    } catch (err) {
      setError(true)
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Styles.Container isMobile={isMobile} brand={brand}>
      <div className="left-wrapper">
        <h1>
          Olá representante, <br />
          Faça <span style={{fontWeight: 'bold'}}>seu login</span>
        </h1>
        <input
          className="inputs"
          value={email}
          onChange={event => setEmail(event.target.value)}
          placeholder="E-mail"
          translate='no'
        />
        <input
          className="inputs"
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          placeholder="Senha"
          translate='no'
        />
        <Loading loading={loading} />
        {error && <Text color="secondary">Usuário ou senha inválidos</Text>}
        <br />
        {/* <div className="action">
          <Styles.BtnForgotPassword onClick={redirectToWhats}>Esqueci a senha</Styles.BtnForgotPassword>
          <Styles.BtnLogin onClick={login}>Entrar</Styles.BtnLogin>
        </div> */}
      </div>
      {
        !isMobile && (
          <div className='right-wrapper'>
            <img src={themes[brand as keyof typeof themes].logo} alt="" />
          </div>
        )
      }

    </Styles.Container>
  )
}

export default Login
