import logoEliv from "../../assets/img/logo-eliv.png"
import logoUsaHemp from "../../assets/img/logo-usa-hemp.png"
import logoRevivid from "../../assets/img/logo-revivid.png"
import logoVolcanic from "../../assets/svg/logo-volcanic.svg"
import logoCannectUsaHemp from "../../assets/img/logo-cannect-usahemp.png"
import logoCannectEliv from "../../assets/img/logo-cannect.png"
import logoCannectRevivid from "../../assets/img/logo-cannect-revivid.png"
import logoCannectVolcanic from "../../assets/img/logo-cannect-volcanic.png"
import logoMoonlion from "../../assets/img/moonlion-logo.png"
import logoValtellinamed from "../../assets/img/logo-valtellinamed.jpeg"
import logoEvona from "../../assets/img/evona-logo.png"
import logoNunatureLabs from "../../assets/img/logo-nunaturelabs.png"
import logoBehemp from "../../assets/img/logo-behemp.png"

import logoNexBiopharma from "../../assets/img/logo-nexbiopharma.png"
import logoCannectNex from "../../assets/img/logo-cannect-nex.png"

import logoSereneCbd from "../../assets/img/logo-serenecbd.png"

export const themes = {
  eliv: {
    colors: {
      primary: '#008AAD',
      secondary: '#3D3D3D',
      tertiary: '#008AAD',
      quartiary: '#3D3D3D',
      primaryLight: '#E0E7EC',
      menu: '#E4E9F2',
      inputBg: '#E4E9F2',
      hover: 'rgba(39, 154, 172, 0.2);',
      login: '#ffffff',
      loginBtn: '#008AAD'
    },
    logo: logoEliv,
    logoCannect: logoCannectEliv
  },
  usahemp: {
    colors: {
      primary: '#6BA543',
      secondary: '#F6F6F6',
      tertiary: '#D8AB27',
      quartiary: '#F0780A',
      primaryLight: '#F6F6F6',
      menu: '#6BA543',
      inputBg: '#F3F3F3',
      hover: 'rgba(91, 148, 91, 0.2);',
      login: '#ffffff',
      loginBtn: '#6BA543'
    },
    logo:logoUsaHemp,
    logoCannect:logoCannectUsaHemp
  },
  revivid: {
    colors: {
      primary: '#173231',
      secondary: '#81C04C',
      tertiary: '#81C04C',
      quartiary: '#F0780A',
      primaryLight: '#F6F6F6',
      menu: '#173231',
      inputBg: '#F3F3F3',
      hover: 'rgba(91, 148, 91, 0.2);',
      login: '#ffffff',
      loginBtn: '#173231'
    },
    logo:logoRevivid,
    logoCannect:logoCannectRevivid
  },
  volcanic: {
    colors: {
      primary: '#9ABEAA',
      secondary: '#6DAD52',
      tertiary: '#173231',
      quartiary: '#F0780A',
      primaryLight: '#F6F6F6',
      menu: '#9ABEAA',
      inputBg: '#F3F3F3',
      hover: 'rgba(91, 148, 91, 0.2);',
      login: '#ffffff',
      loginBtn: '#9ABEAA'
    },
    logo:logoVolcanic,
    logoCannect:logoCannectVolcanic
  },
  moonlion: {
    colors: {
      primary: '#00B3EA',
      secondary: '#00B3EA',
      tertiary: '#00B3EA',
      quartiary: '#F0780A',
      primaryLight: '#F6F6F6',
      menu: '#FFFFFF',
      inputBg: '#F3F3F3',
      hover: 'rgba(91, 148, 91, 0.2);',
      login: '#E3E3E3',
      loginBtn: '#1B4BA4'
    },
    logo:logoMoonlion,
    logoCannect:logoCannectUsaHemp
  },
  valtellinamed: {
    colors: {
      primary: '#565656',
      secondary: '#333333',
      tertiary: '#565656',
      quartiary: '#333333',
      primaryLight: '#F6F6F6',
      menu: '#FFFFFF',
      inputBg: '#F3F3F3',
      hover: 'rgba(91, 148, 91, 0.2);',
      login: '#E3E3E3',
      loginBtn: '#1B4BA4'
    },
    logo: logoValtellinamed,
    logoCannect:logoCannectVolcanic
  },
  evona: {
    colors: {
      primary: '#51312D',
      secondary: '#EF8903',
      tertiary: '#51312D',
      quartiary: '#333333',
      primaryLight: '#F6F6F6',
      menu: '#FFFFFF',
      inputBg: '#F3F3F3',
      hover: 'rgba(91, 148, 91, 0.2);',
      login: '#E3E3E3',
      loginBtn: '#1B4BA4'
    },
    logo: logoEvona,
    logoCannect:logoCannectVolcanic
  },
  nunaturelabs: {
    colors: {
      primary: '#78C242',
      secondary: '#5694FF',
      tertiary: '#78C242',
      quartiary: '#78C242',
      primaryLight: '#78C242',
      menu: '#FFFFFF',
      inputBg: '#F3F3F3',
      hover: 'rgba(91, 148, 91, 0.2);',
      login: '#E3E3E3',
      loginBtn: '#1B4BA4'
    },
    logo: logoNunatureLabs,
    logoCannect:logoCannectVolcanic
  },
  behemp: {
    colors: {
      primary: '#78C242',
      secondary: '#5694FF',
      tertiary: '#78C242',
      quartiary: '#78C242',
      primaryLight: '#78C242',
      menu: '#FFFFFF',
      inputBg: '#F3F3F3',
      hover: 'rgba(91, 148, 91, 0.2);',
      login: '#E3E3E3',
      loginBtn: '#1B4BA4'
    },
    logo: logoBehemp,
    logoCannect:logoCannectVolcanic
  },
  serene: {
    colors: {
      primary: '#008AAD',
      secondary: '#E4E9F2',
      tertiary: '#008AAD',
      quartiary: '#008AAD',
      primaryLight: '#78C242',
      menu: '#FFFFFF',
      inputBg: '#008AAD',
      hover: 'rgba(91, 148, 91, 0.2);',
      login: '#E3E3E3',
      loginBtn: '#1B4BA4'
    },
    logo: logoSereneCbd,
    logoCannect:logoCannectEliv
  },
  nexbiopharma: {
    colors: {
      primary: '#6A62E5',
      secondary: '#211F20',
      tertiary: '#6A62E5',
      quartiary: '#211F20',
      primaryLight: '#211F20',
      menu: '#FFFFFF',
      inputBg: '#F3F3F3',
      hover: 'rgba(91, 148, 91, 0.2);',
      login: '#E3E3E3',
      loginBtn: '#1B4BA4'
    },
    logo: logoNexBiopharma,
    logoCannect:logoCannectNex
  }
}

