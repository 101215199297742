import useMediaQuery from 'hooks/useMediaQuery'
import { OrderProps } from 'hooks/useOrderB2c'
import { MOBILE_WIDTH } from 'utils/constants'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import * as Style from './styles'

interface Props {
  order: OrderProps
}

export const Resume = ({ order }: Props) => {
  const frete = order?.shipping && Number(order?.shipping) > 0 ? Number(order?.shipping) : 140

  const items = order?.items.reduce(
    (acc, item) => {
      const value = order?.coupon?.id ? item?.unitPrice : item?.imputed_value
      const price = value !== null && value !== undefined && Number(value) >= 0 ? Number(value) : Number(item?.unitPrice)

      acc.subtotal += price * item.quantity
      // acc.total = acc.subtotal + frete - Number(acc.discount)

      return acc
    },
    {
      total: order.amount,
      subtotal: 0,
      discount: order?.discount,
      shippingDiscount: order?.shippingDiscount,
      totalDiscount: Number(order?.discount) + Number(order?.shippingDiscount),
      productDiscount: 0,
    }
  )

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  return (
    <Style.Container isMobile={isMobile}>
      <Style.Title>Resumo do Pedido</Style.Title>
      <Style.Prices>
        <Style.LeftContainer>
          {!items.subtotal ? <Skeleton style={{ width: '100px' }} /> : <span>Subtotal</span>}
          {!order.shipping ? <Skeleton style={{ width: '100px' }} /> : <span>Custo de importação</span>}
          {!items.discount ? <Skeleton style={{ width: '100px' }} /> : <span>Descontos</span>}
        </Style.LeftContainer>
        <Style.RightContainer>
          {!items.subtotal ? (
            <Skeleton style={{ width: '100px' }} />
          ) : (
            <span>
              {new Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
                currencyDisplay: 'symbol',
              }).format(items.subtotal)}
            </span>
          )}
          {!order.shipping ? (
            <Skeleton style={{ width: '100px' }} />
          ) : (
            <span>
              {new Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
                currencyDisplay: 'symbol',
              }).format(frete)}
            </span>
          )}
          {!items.discount || !items.shippingDiscount ? (
            <Skeleton style={{ width: '100px' }} />
          ) : (
            <span>
              -{' '}
              {new Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
                currencyDisplay: 'symbol',
              }).format(Number(items.totalDiscount))}
            </span>
          )}
        </Style.RightContainer>
      </Style.Prices>
      <hr />
      <Style.Total>
        {!items.total ? <Skeleton style={{ width: '100px' }} /> : <span className="total-title">Total</span>}
        {!items.total ? (
          <Skeleton style={{ width: '100px' }} />
        ) : (
          <span className="total">
            {new Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
              currencyDisplay: 'symbol',
            }).format(Number(order.amount))}
          </span>
        )}
      </Style.Total>
    </Style.Container>
  )
}
